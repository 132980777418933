import header_main_img from "../assets/images/header_main_img.jpg";
import about_main_img from "../assets/images/about_main_img.jpg";
import form_main_img from "../assets/images/form_main_img.jpg";
import professionals_main_img from "../assets/images/professionals_main_img.jpg";
import video_cover_img from "../assets/images/video_cover_img.jpg";
import customer_img_1 from "../assets/images/customer_img_1.jpg";
import customer_img_2 from "../assets/images/customer_img_2.jpg";
import customer_img_3 from "../assets/images/customer_img_3.jpg";
import customer_img_4 from "../assets/images/customer_img_4.jpg";
import customer_img_5 from "../assets/images/customer_img_5.jpg";
import customer_img_6 from "../assets/images/customer_img_6.jpg";

import portfolio_img_1 from "../assets/images/portfolio_img_1.jpg";
import portfolio_img_2 from "../assets/images/portfolio_img_2.jpg";
import portfolio_img_3 from "../assets/images/portfolio_img_3.jpg";
import dried_fruit from "../assets/images/dried_fruit.jpg";
import logo from "../assets/images/logo.png";
import MainProduct_1 from "../assets/images/MainProduct_1.jpg";
import MainProduct_2 from "../assets/images/MainProduct_2.jpg";
import MainProduct_3 from "../assets/images/MainProduct_3.jpg";
import Card1 from "../assets/images/Card_1.jpg";
import Card2 from "../assets/images/Card_2.jpg";
import Card3 from "../assets/images/Card_3.jpg";
import Card4 from "../assets/images/Card_4.jpg";
import Card5 from "../assets/images/Card_5.jpg";
import Card6 from "../assets/images/Card_6.jpg";
import Card7 from "../assets/images/Card_7.jpg";
import Card8 from "../assets/images/Card_8.jpg";
import Card9 from "../assets/images/Card_9.jpg";
import Card10 from "../assets/images/Card_10.jpg";
import Card11 from "../assets/images/Card_11.jpg";
import Card12 from "../assets/images/Card_12.jpg";
import Card13 from "../assets/images/Card_13.jpg";
import Card14 from "../assets/images/Card_14.jpg";
import Card15 from "../assets/images/Card_15.jpg";
import Card16 from "../assets/images/Card_16.jpg";
import Card17 from "../assets/images/Card_17.jpg";
import Card18 from "../assets/images/Card_18.jpg";
import Card19 from "../assets/images/Card_19.jpg";
import Card20 from "../assets/images/Card_20.jpg";
import Card21 from "../assets/images/Card_21.jpg";
import Card22 from "../assets/images/Card_22.jpg";

const images = {Card1,Card2,Card3,Card4,Card5,Card6,Card7,Card8,Card9,Card10,Card11,Card12,Card13,Card14,Card15,Card16,Card17,Card18,Card19,Card20,Card21,Card22, MainProduct_1, MainProduct_2, MainProduct_3, logo, dried_fruit, header_main_img, about_main_img, form_main_img, professionals_main_img, video_cover_img, customer_img_1, customer_img_2, customer_img_3, customer_img_4, customer_img_5, customer_img_6, portfolio_img_1, portfolio_img_2, portfolio_img_3};

export default images;