import images from "./images";
import {FaPaperPlane, FaEdit, FaRocket, FaShoppingCart, FaFileAlt, FaPhoneAlt, FaEnvelopeOpen, FaMapMarkerAlt} from "react-icons/fa";
import {BiDollarCircle} from "react-icons/bi";
import {ImMagicWand} from "react-icons/im";
import {AiOutlineReload} from "react-icons/ai";

const gradient = "url(#blue-gradient)" ;

const services = [
    {
        id: 1,
        icon: <FaPaperPlane style = {{ fill: gradient }} />,
        title: "Digital Marketing",
        text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing"
    },
    {
        id: 2,
        icon: <BiDollarCircle style = {{ fill: gradient }} />,
        title: "Trade Shows",
        text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing"
    },
    {
        id: 3,
        icon: <FaRocket style = {{ fill: gradient }} />,
        title: "Branding",
        text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing"
    },
    {
        id: 4, 
        icon: <FaEdit style = {{ fill: gradient }} />,
        title: "Content Creation",
        text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing"
    },
    {
        id: 5,
        icon: <ImMagicWand style = {{ fill: gradient }} />,
        title: "Grpahic Design",
        text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing"
    },
    {
        id: 6,
        icon: <FaShoppingCart style = {{ fill: gradient }} />,
        title: "Media Buying",
        text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing"
    }
];

const about = [
    {
        id: 7,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris finibus leo et diam fermentum ullamcorper. Nulla venenatis nibh sollicitudin tincidunt gravida. Nam convallis justo et ligula luctus suscipit. Etiam eu nisi turpis. Donec sollicitudin accumsan quam, rhoncus sagittis metus semper quis. Praesent convallis mauris sed ipsum lobortis facilisis. Nulla cursus sem non nunc sagittis, a volutpat mauris lobortis. Nulla ut feugiat tellus. Nam dictum nisi nec scelerisque auctor"
    }
]

const qualities = [
    {
        id: 8,
        icon: <FaFileAlt style = {{ fill: gradient }} />,
        title: "Ideas & Plans",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod."
    },
    {
        id: 9,
        icon: <AiOutlineReload style = {{ fill: gradient }}  />,
        title: "Prompt Strategies",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod."
    }
];

const features = [
    {
        id: 10,
        title: "Digital Marketing",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et elit vitae lectus convallis scelerisque. Cras vestibulum blandit lorem, at fringilla nisl sollicitudin ac. Nunc venenatis nec neque sed semper. Mauris viverra, sapien sed fringilla egestas, sem felis condimentum augue, vitae sodales sem metus in ex. Aenean massa velit, sollicitudin quis elementum sit amet, vehicula sed nunc."
    },
    {
        id: 11,
        title: "Trade Shows",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et elit vitae lectus convallis scelerisque. Cras vestibulum blandit lorem, at fringilla nisl sollicitudin ac. Nunc venenatis nec neque sed semper. Mauris viverra, sapien sed fringilla egestas, sem felis condimentum augue, vitae sodales sem metus in ex. Aenean massa velit, sollicitudin quis elementum sit amet, vehicula sed nunc."
    },
    {
        id: 12,
        title: "Branding",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et elit vitae lectus convallis scelerisque. Cras vestibulum blandit lorem, at fringilla nisl sollicitudin ac. Nunc venenatis nec neque sed semper. Mauris viverra, sapien sed fringilla egestas, sem felis condimentum augue, vitae sodales sem metus in ex. Aenean massa velit, sollicitudin quis elementum sit amet, vehicula sed nunc."
    },
    {
        id: 13,
        title: "Content Creation",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et elit vitae lectus convallis scelerisque. Cras vestibulum blandit lorem, at fringilla nisl sollicitudin ac. Nunc venenatis nec neque sed semper. Mauris viverra, sapien sed fringilla egestas, sem felis condimentum augue, vitae sodales sem metus in ex. Aenean massa velit, sollicitudin quis elementum sit amet, vehicula sed nunc."
    }, 
    {
        id: 14,
        title: "Graphic Design",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et elit vitae lectus convallis scelerisque. Cras vestibulum blandit lorem, at fringilla nisl sollicitudin ac. Nunc venenatis nec neque sed semper. Mauris viverra, sapien sed fringilla egestas, sem felis condimentum augue, vitae sodales sem metus in ex. Aenean massa velit, sollicitudin quis elementum sit amet, vehicula sed nunc."
    },
    {
        id: 15,
        title: "Media Buying",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et elit vitae lectus convallis scelerisque. Cras vestibulum blandit lorem, at fringilla nisl sollicitudin ac. Nunc venenatis nec neque sed semper. Mauris viverra, sapien sed fringilla egestas, sem felis condimentum augue, vitae sodales sem metus in ex. Aenean massa velit, sollicitudin quis elementum sit amet, vehicula sed nunc."
    }
];

const portfolio = [
    {
        id: 16,
        title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elits",
        image: images.portfolio_img_1,
    },
    {
        id: 17,
        title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elits",
        image: images.portfolio_img_2,
    },
    {
        id: 18,
        title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elits",
        image: images.portfolio_img_3,
    }
];

const items = [
    {
        id: 16,
        title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elits",
        image: images.MainProduct_1,
    },
    {
        id: 17,
        title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elits",
        image: images.MainProduct_2,
    },
    {
        id: 18,
        title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elits",
        image: images.MainProduct_3,
    }
];

const testimonials = [
    {
        id: 19,
        name: "Marie Jordan",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_1,
        rating: 3
    },
    {
        id: 20,
        name: "Ann Brown",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_2,
        rating: 5
    },
    {
        id: 21,
        name: "Andrew Bill",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_3,
        rating: 2
    },
    {
        id: 22,
        name: "Jason Stawer",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_4,
        rating: 4
    },
    {
        id: 23,
        name: "Lisa Green",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_5,
        rating: 2
    },
    {
        id: 24,
        name: "Anna Doe",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_6,
        rating: 4
    }
]

const contact = [
    {
        id: 25,
        icon: <FaPhoneAlt style = {{ fill: gradient }} />,
        info: "+37062993429",
        text: "Darbo dienomis 8:00 - 17:00, savaitgaliais 10:00 - 15:00 "
    },
    {
        id: 26,
        icon: <FaEnvelopeOpen style = {{ fill: gradient }} />,
        info: "testas@testauskas.com",
        text: "Atsakome per 1-2d.d."
    },
    {
        id: 27,
        icon: <FaMapMarkerAlt style = {{ fill: gradient }} />,
        info: "Džiovintos uogos",
        text: "Facebook puslapis"
    }
]

const productList = [
   {
    title:'Rinkiniai',
    image:images.Card1,
    text:'Kalėdiniai rinkiniai. Sudedami pagal pasirinkimus susisiekus.',
    price:'10€-33€'
   },
   {
    title:'Vaisiai',
    image:images.Card2,
    text:'Liofilizuoti obuoliai, ananasai, avietės, kriaušės, braškės, slyvos, vynuogės. 50g.',
    price:'4,5€'
   },
   {
    title:'Braškės ir vynuogės',
    image:images.Card3,
    text:'Liofilizuotos braškių ir vynuogių uogos. 50g.',
    price:'5€'
   },
   {
    title:'Vynuogių žirneliai',
    image:images.Card4,
    text:'Liofilizuotos vynuogės. 30g.',
    price:'4,3€'
   },
   {
    title:'Kriaušės',
    image:images.Card5,
    text:'Liofilizuotos kriaušių skiltelės. 50g.',
    price:'4,5€'
   },
//    {
//     title:'Kanelių vaivorykštė',
//     image:images.Card7,
//     text:'Liofilizuotos uogos, vaisiai, vaniliniai ledai. 70g.',
//     price:'7,5€'
//    },
//    {
//     title:'Vabaliukai',
//     image:images.Card8,
//     text:'Liofilizuotos uogos, vaisiai, vaniliniai ledai. 55g.',
//     price:'5,9€'
//    },
   {
    title:'Slyvos',
    image:images.Card9,
    text:'Liofilizuotos slyvos. 30g.',
    price:'4,5€'
   },
   {
    title:'Smidrų miltai',
    image:images.Card10,
    text:'Liofilizuoti smidrai. 25g.',
    price:'4€'
   },
   {
    title:'Smidrų viršūnėlės',
    image:images.Card11,
    text:'Liofilizuoti smidrai. 20g.',
    price:'4,3€'
   },
   {
    title:'Tinginių šaltibarščiai',
    image:images.Card12,
    text:'Liofilizuoti virti burokėliai, agurkai, krapai, svogūnų laiškai. 45g.',
    price:'5€'
   },
   {
    title:'Daržovės',
    image:images.Card13,
    text:'Liofilizuoti burokėliai, agurkai, paprikos, svogūnėliai, topinambai. 35g.',
    price:'4,5€'
   },
   {
    title:'Pomidorų puselės',
    image:images.Card14,
    text:'Liofilizuotos pomidorų puselės. 25g.',
    price:'4,5€'
   },
   {
    title:'Baravykų druska',
    image:images.Card15,
    text:'Liofilizuoti baravykai su Himalajų druska. 100g.',
    price:'10,5€'
   },
   {
    title:'Rudenėlis',
    image:images.Card17,
    text:'Liofilizuotos morkos, pomidorai, salierai, česnakai, paprika, krapai. 30g.',
    price:'3,7€'
   },
   {
    title:'Prieskoniai Žaluma',
    image:images.Card18,
    text:'Liofilizuoti salierai, česnakai, krapai, kalendros, bazilikai. 20g.',
    price:'3,5€'
   },
   {
    title:'Hidrolatai',
    image:images.Card20,
    text:'Levandų, Jazminų žiedų ir Vingiorykščių hidrolatai.',
    price:'5€-6€'
   },
   {
    title:'Prieskoniai Fantazija',
    image:images.Card21,
    text:'Liofilizuoti česnakai, krapai, svogūnai, bazilikai, citrinžolė, Himalajų druska, juodieji ir kvapieji pipirai. 70g.',
    price:'8€'
   }
//    {
//     title:'Pinigėliai',
//     image:images.Card22,
//     text:'Liofilizuotos uogos, ledai. 70g.',
//     price:'7,5€'
//    }
   ];
   

const sections = {services, about, qualities, features, portfolio, testimonials, contact, items, productList};

export default sections;