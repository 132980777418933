import React from 'react';
import "./About.css";
import images from "../../constants/images";

const About = () => {
    return (
            <div className='container-liofilizacija' id='aboutMe'>
                    <div className='paddings'>
                        <p className='textNormal pAbout'>Liofilizacija (džiovinimas šalčiu) - procesas, kurio dėka džiovinti produktai išlaiko savo formą, spalvą ir skonį, 
                                                         suteikdami progą mėgautis mėgstamomis gėrybėmis ne tik vasaros metu.</p>
                        <p className='textNormal pAbout'>DziovintaUoga.lt produkcija auginama ir šalčiu džiovinama Joniškio rajone esančiame ūkyje. </p>
                        <p className='textSemiBold pAbout'>Norint įsigyti prekę susisiekite žemiau esančiais kontaktais.</p>
                </div>
            </div>
    )
}

export default About;