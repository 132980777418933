import './App.css';
import Header from './components/Header/Header';
import "./assets/js/script";
import ItemCards from './components/ItemCards/ItemCards';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

function Products() {
  return (
    <div className="Products">
      <Navbar />
      <ItemCards />
      <Footer/>
    </div>
  );
}

export default Products;
