import React from 'react';
// import "../Services/Services.css";
import './ItemCards.css';
import images from '../../constants/images';

const SingleItemCard = ({item}) => {
    return (
            <div className="container">
        <div className='itemCard'>
            <div className='flex'>
                <img className='imgItemCards' src={item.image} alt=""/>
                <span className='itemText'>
                    <h4 className='h4Products'>{item.title}</h4>
                    {item.text}
                </span>
                    <p className='priceTag'>{item.price}</p>
                </div>
            </div>
        </div>
    )
}

export default SingleItemCard;