import React from 'react';
import sections from "../../constants/data.js";
import SingleItem from './SingleItem.jsx';
import "./ItemSection.css";
import {Link} from 'react-router-dom';

const ItemsSection = () => {
    return (
        <div className='container-ItemSection'>
            <h5 className='h5ItemSection'>Daugiau nei 20 gaminių rūšių dovanoms, užkandžiams, desertams, gaminimui - jūsų ar artimųjų namams.</h5>
            <section className='itemSection' id="items">
                    <div className='item-list grid text-white text-center'>
                        {
                            sections.items.map(item => {
                                return (
                                    <SingleItem item = {item} key = {item.id}/>
                                )
                            })
                        }
                    </div>
                <div className='flex' style={{justifyContent: 'space-around'}}>
                        <Link to='/Products'><button type='button' className='btn-ItemSection'>Daugiau</button></Link>
                    </div>
            </section>
            
        </div>
    )
}

export default ItemsSection;