import {React, useState} from "react";
import sections from "../../constants/data.js";
import SingleItemCard from "./SingleItemCard.jsx";
import "./ItemCards.css";
import {Link} from 'react-router-dom';

const ItemCards = () => {
    const [searchInput, setSearchInput] = useState("");
    let newList = sections.productList;
    
    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
      };
      
      if (searchInput.length > 0) {
        newList = sections.productList.filter((product) => 
             product.title.includes(searchInput))
      };
      const onClick = () => {
        window.location.href = "#footer";
      };

  return (
            <div className='container-cards'>
                            <h5 className="text-black h5Products">Produktai</h5>
                            <p className="p-title">Išsirinkus prekę - <Link className="linkStyle" onClick={onClick}>susisiekite</Link></p>
                <div className="innerContainer flex">
            {/* <input type="search" placeholder="Search here" onChange={handleChange} value={searchInput} /> */}

                    <div className='item-list itemGrid text-white text-center'>
                        {
                            newList.map((product) => {
                                return (
                                    <SingleItemCard item = {product} key = {product.title}/>
                                )
                            })
                        }
                    </div>
                {/* <div className='flex' style={{justifyContent: 'space-around'}}>
                        <button type='button' className='btn'>Daugiau</button>
                    </div> */}
            </div>
            </div>
  )
}

export default ItemCards;