import React from 'react';
import "./ItemSection.css"
import {Link} from 'react-router-dom';

const SingleItem = ({item}) => {
    return (
       <Link to='/Products'> <div className='translate-effect itemSectionCard'>
            <img className='itemSectionImg' src={item.image} alt=""/>
            {/* <div className="container"> */}
                {/* <h4><b>{item.title}</b></h4> */}
                {/* <p>{item.text}</p> */}
            {/* </div> */}
        </div>
        </Link>
    )
}

export default SingleItem;