import React from 'react';
import "./Footer.css";
import {Link} from 'react-router-dom';
import { FaPhone, FaFacebook, FaEmail } from 'react-icons/fa';
import {IoMdMail} from "react-icons/io";
import "../Contact/Contact.css";
import "../Navbar/Navbar.css";

const Footer = () => {

    let date = new Date();

  return (
    <footer className='footer flex flex-center' id = "footer">
            <ul className='navbar-social flex flex-center'>
                                <li className='text-white'>
                                <FaPhone className='iconPadding' /><Link to="" className='flex-center'>+37061440858</Link>
                                </li>
                                <li className='text-white'>
                                <FaFacebook  className='iconPadding' /><Link to="https://www.facebook.com/profile.php?id=61553925397604" target='_blank' className=''>Facebook</Link>
                                </li>
                                <li className='text-white'>
                                <IoMdMail className='iconPadding' /><Link to="" className='flex-start'>dziovintauoga@gmail.com</Link>
                                </li>
                            </ul>
    </footer>
  )
}

export default Footer