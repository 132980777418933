import React from 'react';
import Navbar from '../Navbar/Navbar';
import "../../App.css";
import "./Header.css";
import {FaPaperPlane} from "react-icons/fa";

const Header = () => {
    return (
        <header className='header flex'>
            <Navbar />
            <div className='container-inner-header'>
                <div className='header-content'>
                    <h1 className='header-title'>Natūralus vitaminų šaltinis ir šaltojo sezono metu</h1>
                    <p className='text-lead '>Natūralūs šalčiu džiovinti produktai. <br/> Iš Lietuvos kaimo į jūsų namus.</p>
                    <a href="/Products"><button type='button' className='header-btn'>Gaminiai</button></a>
                </div>
            </div>
        </header>
    )
}
export default Header