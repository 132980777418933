import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import "./Navbar.css";
import images from "../../constants/images";

const Navbar = () => {
    const toContacts = () => {
        window.location.href = "#footer";
      };

      const toAboutMe = () => {
        var myEle = document.getElementById("aboutMe");
        if(!myEle) {
            window.location.href = "/";
        } 
        window.location.href = "#aboutMe";
      };

    const [navToggle, setNavToggle] = useState(false);
    const navHandler = () => {
        setNavToggle(prevData => !prevData);
    }
    return (
        <nav className='navbar w-100 flex'>
            <div className='container-inner w-100'>
                <div className='navbar-content flex fw-7'>
                    <div className='brand-and-toggler flex w-100'>
                        <Link to="/" className='navbar-brand'><img src= {images.logo} alt="" style={{height: 55}} /></Link>
                        <Link to="/" className='text-black fs-24 websiteName'>DziovintaUoga.lt</Link>
                        <div type='button' className={`hamburger-menu ${navToggle ? 'hamburger-menu-change' : '' }`} onClick={navHandler}>
                            <div className='bar-top'></div>
                            <div className='bar-middle'></div>
                            <div className='bar-bottom'></div>
                        </div>
                    </div>
                    <div className={`navbar-collapse ${navToggle ? 'show-navbar-collapse' : ''}`}>
                        <div className='navbar-collapse-content flex-end'>
                            <ul className='navbar-nav '>
                                <li className='text-black fs-24'>
                                <Link to="/Products">Produktai</Link>
                                </li>
                                <li className='text-black fs-24'>
                                <Link onClick={toAboutMe}>Apie mane</Link>
                                </li>
                                <li className='text-black fs-24'>
                                    <Link onClick={toContacts}>Kontaktai</Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
export default Navbar